<template>
  <el-main>
    <div class="add">
      <SmallCard :info="smallInfo" @clickFn="$router.push('/extension/addEnclosure')"></SmallCard>
    </div>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="地理围栏商圈：">
        <el-input v-model="trading_area" placeholder="请输入店铺围栏标题" size="small"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select size="small" v-model="status" placeholder="请选择">
          <el-option
            v-for="item in opStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getList()">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column
        prop="trading_area"
        label="地理围栏商圈"
        width="220"
        align="center"
      ></el-table-column>
      <el-table-column prop="longitude" label="经度" align="center"></el-table-column>
      <el-table-column prop="latitude" label="纬度" align="center"></el-table-column>
      <el-table-column prop="scope" label="范围" align="center"></el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            :active-value="1"
            :inactive-value="0"
            @change="upStatusEnclosure(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="$router.push({path:'/extension/editEnclosurePush',query:{trading_area:scope.row.trading_area}})" type="text" size="small">编辑推送</el-button>
          <el-button @click="remove(scope.row.id, scope.$index)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Paging
      class="paging"
      :total="total_number"
      :page="page"
      :pageNum="rows"
      @updatePageNum="updateData"
    ></Paging>
  </el-main>
</template>

<script>
import Paging from '../../components/paging.vue'
import SmallCard from '@/components/smallCardBox.vue'
export default {
  components: { Paging, SmallCard },
  data() {
    return {
      smallInfo: {
        img: require('@/assets/smallCardImgs/4.png'),
        title: '地理围栏',
        desc: '针对华为快应用用户营销(最多可设置200个)',
        btnTxt: '创建地理围栏'
      },
      list: [],
      page: 1,
      rows: 10,
      trading_area: '',
      status: 0,
      total_number: 0,
      opStatus: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '停用'
        },
        {
          value: 2,
          label: '启用'
        }
      ]
    }
  },
  created() {
    let utils = this.$store.state.utils
    if (utils.is_record) {
      this.page = utils.page
      this.rows = utils.rows
      utils.is_record = !1
    }
    this.getList()
  },
  methods: {
    getList() {
      this.$axios
        .post(this.$api.push.enclosureList, {
          page: this.page,
          rows: this.rows,
          trading_area: this.trading_area,
          status: this.status
        })
        .then((res) => {
          if (res.code == 0) {
            let list = res.result.list
            this.list = list
            this.total_number = res.result.total_number
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    edit(row) {
      this.$router.push({
        path: '/extension/editEnclosure',
        query: {
          info: JSON.stringify(row),
          page: this.page,
          rows: this.rows
        }
      })
    },
    //改变状态
    upStatusEnclosure(row) {
      this.$axios.post(this.$api.push.upStatusEnclosure, { id: row.id }).then((res) => {
        if (res.code == 0) {
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    remove(id, index) {
      this.$confirm('确认删除此围栏？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios
          .post(this.$api.push.delEnclosure, {
            id: id
          })
          .then((res) => {
            if (res.code == 0) {
              this.list.splice(index, 1)
              this.$message({
                message: '删除成功',
                type: 'success'
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      })
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val
        this.getList()
      } else {
        this.page = val
        this.getList()
      }
    },
    cancelSearch() {
      this.trading_area = ''
      this.status = 0
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
